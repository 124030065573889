import React from "react"

import SEO from "../components/seo"
import LayoutsComponent from '../layouts/LayoutsComponent';
import CargaArchivos from '../components/pages/CargaArchivos'
import GlobalFooterProvider from "../context/GlobalStateFooterContext";

const CargaArchivosPage = ({ pageContext, location }) => (
  <GlobalFooterProvider>
  <LayoutsComponent
    broker={pageContext.broker}
    layout={pageContext.layout}
    pageContext={pageContext}
    location={location}
    companyName={pageContext.company}
    gradient
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <CargaArchivos pageContext={pageContext} companyName={pageContext.company} />
      </>
    )}
  />
  </GlobalFooterProvider>
)

export default CargaArchivosPage